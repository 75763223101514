import axios from "axios";
import React, { useState, useEffect } from "react";
import useSWR from "swr";
import { isLoggedIn } from "../Utils/isLoggedIn.js";
import { Fetcher } from "../Utils/AxiosWrapper.js";
import useCurrentWidth from "./useCurrentWidth.jsx";
import Layout from "./layout.jsx";
export default function Review({exid, id, cat, close = () => {}}) {
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');
  const width = useCurrentWidth();

  const handleRatingChange = (value) => {
    setRating(value);
  };
  function submitReview() {
    Fetcher("POST", "/api/Review", {
      ItemID: id, 
      ExID: exid, 
      Reviewer: isLoggedIn(),
      Review: review,
      Rating: rating,
      Category: cat
    })
    .then(({data}) => {
      console.log("post succeeded");
      close();
    })
    .catch((err) => {
      console.log("post failed");
      console.log(err);
      close();
    });
  }
  function getCurrentDate() {
    const date = new Date();
    return date.toLocaleDateString();

  }
  return (
    width > 530 ?
      <div style={{display: `flex`, justifyContent: `center`, alignItems: `center`, alignContent: `center`}}>
        <div>
        <h2 style={{color: `#e37100`, marginBottom: `30px`}}>We'd <img src='/Images/ReviewHeart.png' style={{height: `30px`}} /> to hear from you!</h2>
        <div style={{color: `#e37100`, fontSize: `18px`, marginBottom: `10px`}}>Rate Your Experience</div>
        <div className="rating" style={{marginBottom: `10px`}}>
            {[5, 4, 3, 2, 1].map((value) => (
              <React.Fragment key={value}>
                <input
                  type="radio"
                  id={`star2${value}`}
                  name="rating2"
                  value={value}
                  checked={rating === value}
                  onChange={() => handleRatingChange(value)}
                />
                <label htmlFor={`star2${value}`} title={`${value} star2`}></label>
              </React.Fragment>
            ))}
          </div>
          <div style={{display: `flex`, justifyContent: `space-between`, marginBottom: `10px`}}><div style={{color: `#e37100`, fontSize: `18px`}}>Your Review</div><div style={{color: `#e37100`, fontSize: `18px`}}>{getCurrentDate()}</div></div>
          <div style={{display: `flex`, justifyContent: `center`}}><textarea value={review} onChange={(e) => {setReview(e.target.value)}} style={{border: `1px solid #e37100`, borderRadius: `10px`}} rows="6" cols="50" /></div>
          <div style={{display: `flex`, justifyContent: `flex-end`, marginTop: `10px`}}><button style={{marginRight: `10px`}} onClick={close} >Cancel</button><button onClick={() => {submitReview()}} >Submit</button></div>
        </div>
      </div> :
            <div style={{display: `flex`, justifyContent: `center`, alignItems: `center`, alignContent: `center`, minHeight: `calc( 100vh - 330px )`}}>
            <div>
            <h3 style={{color: `#e37100`, marginBottom: `30px`, fontSize: `16px`}}>We'd <img src='/Images/ReviewHeart.png' style={{height: `25px`}} /> to hear from you!</h3>
            <div style={{color: `#e37100`, fontSize: `13px`, marginBottom: `10px`}}>Rate Your Experience</div>
            <div className="rating" style={{marginBottom: `10px`}}>
                {[5, 4, 3, 2, 1].map((value) => (
                  <React.Fragment key={value}>
                    <input
                      type="radio"
                      id={`star${value}`}
                      name="rating"
                      value={value}
                      checked={rating === value}
                      onChange={() => handleRatingChange(value)}
                    />
                    <label htmlFor={`star${value}`} title={`${value} star`}></label>
                  </React.Fragment>
                ))}
              </div>
              <div style={{display: `flex`, justifyContent: `space-between`, marginBottom: `10px`}}><div style={{color: `#e37100`, fontSize: `13px`}}>Your Review</div><div style={{color: `#e37100`, fontSize: `13px`}}>{getCurrentDate()}</div></div>
              <div style={{display: `flex`, justifyContent: `center`}}><textarea value={review} onChange={(e) => {setReview(e.target.value)}} style={{border: `1px solid #e37100`, borderRadius: `10px`}} rows="6" cols={width > 450 ? "40" : "30"} /></div>
              <div style={{display: `flex`, justifyContent: `flex-end`, marginTop: `10px`}}><button style={{marginRight: `10px`}} onClick={close} >Cancel</button><button onClick={() => {submitReview()}} >Submit</button></div>
            </div>
          </div>
  )
}
