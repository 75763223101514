import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import useCurrentWidth from "./useCurrentWidth";
import Review from './review';
import { setCookie } from "../Utils/Cookies";
import { isLoggedIn } from "../Utils/isLoggedIn.js";
import useSWR from 'swr';
import { getFetcher } from "../Utils/AxiosWrapper";
import Context from "../Utils/context";

export default function ItemDetails() {
    const width = useCurrentWidth();
    const context = useContext(Context);
    const [mainImage, setMainImage] = useState("");
    const [openReviewModal, setOpenReviewModal] = useState(false);
    const queryString = window.location.search;
    const [addedItem, setAddedItem] = useState(false);
    const [showOne, setShowOne] = useState(1);
    const urlParams = new URLSearchParams(queryString);
    const [data3, setData3] = useState("");
    const [isMensJoggers, setIsMensJoggers] = useState(true);
    const [showAll, setShowAll] = useState(false);
    const [data2, setData2] = useState("");
    const [variants, setVariants] = useState("");
    const [size, setSize] = useState("");
    const [qty, setQty] = useState(1);
    const [showGuidline, setShowGuideline] = useState(false);
    const { data, error } = useSWR("/api/Products/"+urlParams.get("id"), getFetcher);
    useEffect(() => {
      if (data)
      {
        console.log("data",data)
        setMainImage(data.result.sync_product.thumbnail_url);
        setIsMensJoggers(data.result.sync_product.name.split(":")[0].includes("Men’s Joggers"));
        setData3(data.result.sync_product);
        setData2(data.result.sync_variants[0]);
        setVariants(data.result.sync_variants);
      }
      },[data])
      const showMockups = function()
      {
          return urlParams.get("found") === "true"
      }

      function GetReviews({cat}) {
        const { data, error } = useSWR("/api/Review?category="+cat, getFetcher);
        function convertDate(date){
          let myDate = new Date(date);
          return myDate.toLocaleDateString();
        }
        return (<div style={{maxWidth: `580px`}}>
          {(data && data.data && data.data.length > 0) && 
          <p style={{color: `#e37100`, fontWeight: `bold`}}>Customer Reviews</p>}
          {(data && data.data) && data.data.map((review, index) => (
            <div key={index} style={{marginBottom: `20px`}}>
             <div style={{display:  `flex`, justifyContent: `space-between`}}>
                <div>{review.history.reviewer}</div>
                <div>{convertDate(review.history.datereviewed)}</div>
              </div>
              <div className="rating2" style={{marginBottom: `5px`, marginTop: `5px`}}>
            {[5, 4, 3, 2, 1].map((value) => (
              <React.Fragment key={value}>
                <input
                  type="radio"
                  id={`star${value}`}
                  name="rating"
                  value={value}
                  readOnly
                  checked={review.history.rating == value}
                />
                <label style={{cursor: `default`, color: review.history.rating < value ? `#888` : `#e37100`}} htmlFor={`star${value}`} title={`${value} star`}></label>
              </React.Fragment>
            ))}
          </div>
              <div>{review.history.review}</div>
            </div>
      
          ))}
        </div>)
      } 
      const getVariant = function(name)
      {
        if (variants.length == 1)
        {
          return variants[0];
        }
        if (name.includes("Men’s Button Shirt") || name.includes("Women's Midi Dress") || name.includes("Kid's") || name.includes("Youth"))
        {
          switch(size)
          {
            case "2XS": case"2T": case"8": return variants[0];
            case "XS" : case"3T": case"10": return variants[1];
            case "S" : case"4T": case"12": return variants[2];
            case "M" : case"5T": case"14": return variants[3];
            case "L" : case"6": case"16": return variants[4];
            case "XL" : case"6X": case"18": return variants[5];
            case "2XL" : case"7": case"20": return variants[6];
            case "3XL" : return variants[7];
            case "4XL" : return variants[8];
            case "5XL" : return variants[9];
            case "6XL" : return variants[10];
            default: return variants[0];
          }
        }
        else{
          switch(size)
          {
            case "XS" : case "5": case "3-6m": case "SM": return variants[0];
            case "S" : case "5.5": case "6-12m": case "LXL": return variants[1];
            case "M" : case "6": case "12-18m": return variants[2];
            case "L" : case "6.5": case "18-24m": return variants[3];
            case "XL" : case "7":return variants[4];
            case "2XL" : case "7.5":return variants[5];
            case "3XL" : case "8":return variants[6];
            case "8.5": return variants[7];
            case "9": return variants[8];
            case "9.5": return variants[9];
            case "10": return variants[10];
            case "10.5": return variants[11];
            case "11": return variants[12];
            case "11.5": return variants[13];
            case "12": return variants[14];
            case "12.5": return variants[15];
            case "13": return variants[16];
            default: return variants[0];
          }
        }

      }

  return (
      <>
        <Modal
        className="termsModal"
        style={{ border: `none` }}
        show={showGuidline}
        onHide={() => {setShowGuideline(!showGuidline)}}
        centered
        scrollable={true}
      >
        <Modal.Header
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(255,0,0), rgba(252,195,10))`,
            height: `40px`,
            fontWeight: `bold`,
            color: `white`,
            border: `none`,
            
          }}
          closeButton={false}
        >
          <Modal.Title className="modalHeader">
            Sizing Guidelines
          </Modal.Title>
          <img
            style={{
              marginTop: `-6px`,
              marginRight: `-10px`,
              cursor: "pointer",
              height: `20px`,
            }}
            onClick={() => {setShowGuideline(!showGuidline)}}
            src={window.location.origin + "/Images/closeTerms.png"}
            alt="close button"
          />
        </Modal.Header>
        <Modal.Body
          style={{
            background: `linear-gradient(rgba(255, 100, 0, 0.2), rgba(252, 195, 10, 0.2))`,
            border: `none`,
            maxHeight: `80vh`,
            overflowY: `auto`,
          }}
          className="loginModalBody"
        >
          <h2>Body measurement guide</h2>
          <p>This size guide shows body measurements. We suggest ordering a size up when your measurements are between sizes.</p>
          <div style={{display: `flex`}}>
          <img
            style={{
              cursor: "pointer",
              height: `500px`,
            }}
            src={window.location.origin + (isMensJoggers ? "/Images/body.png" : "/Images/wbody.png")}
            alt="Size Guide Body Diagram"
          />
          <div>
          <p>For all horizontal measurements, please keep the tape measure parallel to the ground.</p>
          <h3>A Chest</h3>
          <p>Put one end of the tape measure on the fullest part of the chest and bring the tape around the back (under the armpits, over the shoulder blades) to where you started.</p>
          <h3>B Waist</h3>
          <p>Place the tape on the narrowest part of the waist and measure around.</p>
          <h3>C Hips</h3>
          <p>Put the beginning of the tape measure on one hip and bring the tape across the fullest part of the hips back to where you started measuring.</p>
          </div>
          </div>
          {isMensJoggers ? <div style={{marginTop: `40px`}}>
            <h2>Size Chart</h2>
            <h3>Inches</h3>
            <div style={{display: `flex`, justifyContent: `space-between`}}>
              <div>
                <h3>Size</h3>
                <p>XS</p>
                <p>S</p>
                <p>M</p>
                <p>L</p>
                <p>XL</p>
                <p>2XL</p>
                <p>3XL</p>
              </div>
              <div>
              <h3>Chest</h3>
                <p>36.25</p>
                <p>37.75</p>
                <p>39.375</p>
                <p>42.5</p>
                <p>45.625</p>
                <p>48.875</p>
                <p>52</p>
              </div>
              <div>
              <h3>Waist</h3>
                <p>29.875</p>
                <p>31.5</p>
                <p>33.125</p>
                <p>36.25</p>
                <p>39.375</p>
                <p>42.5</p>
                <p>45.625</p>
              </div>
              <div>
              <h3>Hips</h3>
                <p>37</p>
                <p>38.625</p>
                <p>40.125</p>
                <p>43.25</p>
                <p>46.5</p>
                <p>49.625</p>
                <p>52.75</p>
              </div>
            </div>
          </div> 
          :
          <div style={{marginTop: `40px`}}>
          <h2>Size Chart</h2>
          <h3>Inches</h3>
          <div style={{display: `flex`, justifyContent: `space-between`}}>
            <div>
              <h3>Size</h3>
              <p>XS</p>
              <p>S</p>
              <p>M</p>
              <p>L</p>
              <p>XL</p>
              <p>2XL</p>
              <p>3XL</p>
            </div>
            <div>
            <h3>Chest</h3>
              <p>33.125</p>
              <p>34.625</p>
              <p>36.25</p>
              <p>39.375</p>
              <p>42.5</p>
              <p>45.625</p>
              <p>48.875</p>
            </div>
            <div>
            <h3>Waist</h3>
              <p>25.25</p>
              <p>26.75</p>
              <p>28.375</p>
              <p>31.5</p>
              <p>34.625</p>
              <p>37.75</p>
              <p>41</p>
            </div>
            <div>
            <h3>Hips</h3>
              <p>35.375</p>
              <p>37</p>
              <p>38.625</p>
              <p>41.75</p>
              <p>44.875</p>
              <p>48</p>
              <p>52.125</p>
            </div>
          </div>
        </div>
          }
        </Modal.Body>
      </Modal>
      <Modal
        className="termsModal"
        style={{ border: `none` }}
        show={addedItem}
        onHide={() => {setAddedItem(!addedItem)}}
        centered
        scrollable={true}
      >
        <Modal.Header
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(255,0,0), rgba(252,195,10))`,
            height: `40px`,
            fontWeight: `bold`,
            color: `white`,
            border: `none`,
            
          }}
          closeButton={false}
        >
          <Modal.Title className="modalHeader">
            Item Added
          </Modal.Title>
          <img
            style={{
              marginTop: `-6px`,
              marginRight: `-10px`,
              cursor: "pointer",
              height: `20px`,
            }}
            onClick={() => {setAddedItem(!addedItem)}}
            src={window.location.origin + "/Images/closeTerms.png"}
            alt="close button"
          />
        </Modal.Header>
        <Modal.Body
          style={{
            background: `linear-gradient(rgba(255, 100, 0, 0.2), rgba(252, 195, 10, 0.2))`,
            border: `none`,
            maxHeight: `80vh`,
            overflowY: `auto`,
          }}
          className="loginModalBody"
        >
            <p style={{color: `black`}}>Successfully added item(s) to cart.</p>
        </Modal.Body>
      </Modal>
      {data3 && data2 && <div style={{paddingLeft: (width < 975 && width > 650) ? `0px` : `25px`, paddingRight: (width < 975 && width > 650) ? `0px` : `25px`, color: `black`, paddingTop: `50px`, paddingBottom: `70px`, display: width > 975 ? `flex` : `block`, justifyContent: `space-between`}}>
        {(showMockups() && width > 975) ? 
        <div style={{display: `flex`, justifyContent: `center`, alignContent: `center`, alignItems: `center`}}>
          <div style={{margin: `4vw 0px 0px 10vw`}}>
          <div onClick={() => {setShowOne(1)}}>{<img style={{margin: `0px`, width: `8vw`}} src={"https://strongerthanmed.s3.us-west-2.amazonaws.com/"+urlParams.get("exid")+"/Front.png"}/>}</div>
          <div onClick={() => {setShowOne(2)}}>{<img style={{margin: `0px`, width: `8vw`}} src={"https://strongerthanmed.s3.us-west-2.amazonaws.com/"+urlParams.get("exid")+"/Back.png"}/>}</div>
          <div onClick={() => {setShowOne(3)}}> {<img style={{margin: `0px`, width: `8vw`}} src={"https://strongerthanmed.s3.us-west-2.amazonaws.com/"+urlParams.get("exid")+"/Left.png"}/>}</div>
          <div onClick={() => {setShowOne(4)}}> {<img style={{margin: `0px`, width: `8vw`}} src={"https://strongerthanmed.s3.us-west-2.amazonaws.com/"+urlParams.get("exid")+"/Right.png"}/>}</div>
            </div>
          <div>
            {showOne == 1 && <img style={{margin: `0px`, width: `35vw`}} src={"https://strongerthanmed.s3.us-west-2.amazonaws.com/"+urlParams.get("exid")+"/Front.png"}/>}
            {showOne == 2 && <img style={{margin: `0px`, width: `35vw`}} src={"https://strongerthanmed.s3.us-west-2.amazonaws.com/"+urlParams.get("exid")+"/Back.png"}/>}
            {showOne == 3 && <img style={{margin: `0px`, width: `35vw`}} src={"https://strongerthanmed.s3.us-west-2.amazonaws.com/"+urlParams.get("exid")+"/Left.png"}/>}
            {showOne == 4 && <img style={{margin: `0px`, width: `35vw`}} src={"https://strongerthanmed.s3.us-west-2.amazonaws.com/"+urlParams.get("exid")+"/Right.png"}/>}
          </div>
        </div>
        
        : (width > 975) ? <div><img style={{width: width > 975 ? `45vw` : `90vw` }} src={mainImage}/></div> : <div/>}
        { (showMockups() && width < 975) && <>
          <div style={{display: `flex`, justifyContent: `center`}}>
            {showOne == 1 && <img style={{margin: `0px`, width: width > 600 ? `40vw` : `70vw`}} src={"https://strongerthanmed.s3.us-west-2.amazonaws.com/"+urlParams.get("exid")+"/Front.png"}/>}
            {showOne == 2 && <img style={{margin: `0px`, width: width > 600 ? `40vw` : `70vw`}} src={"https://strongerthanmed.s3.us-west-2.amazonaws.com/"+urlParams.get("exid")+"/Back.png"}/>}
            {showOne == 3 && <img style={{margin: `0px`, width: width > 600 ? `40vw` : `70vw`}} src={"https://strongerthanmed.s3.us-west-2.amazonaws.com/"+urlParams.get("exid")+"/Left.png"}/>}
            {showOne == 4 && <img style={{margin: `0px`, width: width > 600 ? `40vw` : `70vw`}} src={"https://strongerthanmed.s3.us-west-2.amazonaws.com/"+urlParams.get("exid")+"/Right.png"}/>}
        </div>
        <div style={{display: `flex`, justifyContent: `center`, margin: data3.name.split(":")[0].includes("Duffle Bag") ? `-40px 0px 40px 0px` : `20px 0px 40px 0px`}}>
          <div onClick={() => {setShowOne(1)}}>{<img style={{margin: `0px`, width: `12vw`}} src={"https://strongerthanmed.s3.us-west-2.amazonaws.com/"+urlParams.get("exid")+"/Front.png"}/>}</div>
          <div onClick={() => {setShowOne(2)}}>{<img style={{margin: `0px`, width: `12vw`}} src={"https://strongerthanmed.s3.us-west-2.amazonaws.com/"+urlParams.get("exid")+"/Back.png"}/>}</div>
          <div onClick={() => {setShowOne(3)}}> {<img style={{margin: `0px`, width: `12vw`}} src={"https://strongerthanmed.s3.us-west-2.amazonaws.com/"+urlParams.get("exid")+"/Left.png"}/>}</div>
          <div onClick={() => {setShowOne(4)}}> {<img style={{margin: `0px`, width: `12vw`}} src={"https://strongerthanmed.s3.us-west-2.amazonaws.com/"+urlParams.get("exid")+"/Right.png"}/>}</div>
            </div>
          </>}
        <div style={{width: width < 1300 ? width < 976 ? `100%` : `70%` : `50%`, display: `flex`, justifyContent: `center` }}>
          <div>
        <div style={{display: `flex`, justifyContent: `center`, maxWidth: width < 976 ? `100%` : `580px`}}><div style={{fontSize: `40px`, lineHeight: `45px`, color: `#e37100`}}>{data3.name.split(":")[0]}</div></div>
        <div style={{display: `flex`, justifyContent: `center`, maxWidth: width < 976 ? `100%` : `580px`}}><div style={{fontSize: `25px`, lineHeight: `45px`}}>{data3.name.split(":")[1]}</div></div>
            <div style={{marginTop: `30px`, marginBottom: `30px`, fontSize: `25px`}}>${data2.retail_price.split(".")[0]}</div>
            <div style={{display: width > 975 ? `flex` : `block`}}>
        {data3.name.split(":")[0].includes("Shoes") ? <select
          id="size"
          className="dropdowns"
          onChange={(e) => setSize(e.target.value)}
        >
          <option value="month">Size</option>
          <option value="5">5</option>
          <option value="5.5">5.5</option>
          <option value="6">6</option>
          <option value="6.5">6.5</option>
          <option value="7">7</option>
          <option value="7.5">7.5</option>
          <option value="8">8</option>
          <option value="8.5">8.5</option>
          <option value="9">9</option>
          <option value="9.5">9.5</option>
          <option value="10">10</option>
          <option value="10.5">10.5</option>
          <option value="11">11</option>
          <option value="11.5">11.5</option>
          <option value="12">12</option>
          {data3.name.split(":")[0].includes("Men’s Shoes") && <option value="12.5">12.5</option>}
          {data3.name.split(":")[0].includes("Men’s Shoes") && <option value="13">13</option>}

        </select> : 
        data3.name.split(":")[0].includes("Kid's") ? <select
        id="size"
        className="dropdowns"
        onChange={(e) => setSize(e.target.value)}
      >
      <option value="month">Size</option>
      <option value="2T">2T</option>
      <option value="3T">3T</option>
      <option value="4T">4T</option>
      <option value="5T">5T</option>
      <option value="6">6</option>
      <option value="6X">6X</option>
      <option value="7">7</option>

      </select> :

      data3.name.split(":")[0].includes("Youth") ? <select
      id="size"
      className="dropdowns"
      onChange={(e) => setSize(e.target.value)}
      >
      <option value="month">Size</option>
      <option value="8">8</option>
      <option value="10">10</option>
      <option value="12">12</option>
      <option value="14">14</option>
      <option value="16">16</option>
      <option value="18">18</option>
      <option value="20">20</option>

      </select> :
        
        
        data3.name.split(":")[0].includes("Onesie") ? <select
          id="size"
          className="dropdowns"
          onChange={(e) => setSize(e.target.value)}
        >
          <option value="month">Size</option>
          <option value="3-6m">3-6m</option>
          <option value="6-12m">6-12m</option>
          <option value="12-18m">12-18m</option>
          <option value="18-24m">18-24m</option>

        </select> :
        data3.name.split(":")[0].includes("Fitted") ? <select
        id="size"
        className="dropdowns"
        onChange={(e) => setSize(e.target.value)}
      >
        <option value="month">Size</option>
        <option value="SM">S/M</option>
        <option value="LXL">L/XL</option>

      </select> : (variants.length > 1) && <select
          id="size"
          className="dropdowns"
          onChange={(e) => setSize(e.target.value)}
        >
          <option value="month">Size</option>
          {((data3.name.split(":")[0].includes("Men’s Button Shirt")) || (data3.name.split(":")[0].includes("Women's Midi Dress")))
           && <option value="2XS">2XS</option>}
          <option value="XS">XS</option>
          <option value="S">S</option>
          <option value="M">M</option>
          <option value="L">L</option>
          <option value="XL">XL</option>
          {(!data3.name.split(":")[0].includes("Women's Dress")) 
          && (!data3.name.split(":")[0].includes("Women's Leggings"))
          && (!data3.name.split(":")[0].includes("Women's Tank Tops"))
           && <option value="2XL">2XL</option>}
          {(!data3.name.split(":")[0].includes("Women's Dress")) && (!data3.name.split(":")[0].includes("Men’s Tank Top"))
          && (!data3.name.split(":")[0].includes("Men’s Tank Top"))
          && (!data3.name.split(":")[0].includes("Men’s T-shirt"))
          && (!data3.name.split(":")[0].includes("Women's Leggings"))
          && (!data3.name.split(":")[0].includes("Women's Sports Bra"))
          && (!data3.name.split(":")[0].includes("Women's Tank Tops"))
          && (!data3.name.split(":")[0].includes("Women's T-Shirt"))
          && <option value="3XL">3XL</option>}
          {((data3.name.split(":")[0].includes("Men’s Button Shirt")) || (data3.name.split(":")[0].includes("Women's Midi Dress")))
           && <option value="4XL">4XL</option>}
           {((data3.name.split(":")[0].includes("Men’s Button Shirt")) || (data3.name.split(":")[0].includes("Women's Midi Dress")))
           && <option value="5XL">5XL</option>}
           {((data3.name.split(":")[0].includes("Men’s Button Shirt")) || (data3.name.split(":")[0].includes("Women's Midi Dress")))
           && <option value="6XL">6XL</option>}

        </select>}
        <select
          id="size"
          className="dropdowns"
          style={{marginLeft: ((width > 975) && (variants.length > 1)) ? `50px` : `0px`}}
          onChange={(e) => setQty(e.target.value)}
        >
          <option value="month">Qty</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>

        </select>
        <button style={{ display: `flex`, justifyContent: `flex-end`, marginLeft: width > 975 ? `50px` : `0px`, marginTop: width > 975 ? `0px` : `15px`, display: `block`, marginBottom: width > 975 ? `0px` : `15px`, color: `white`, cursor: `pointer`, textTransform: `uppercase`}} 
        onClick={() => {
          if (size === "" && variants.length > 1)
          {
            alert("Please select a size");
          }
          else
          {setCookie("cart", JSON.stringify({
            image: mainImage, 
            price: data2.retail_price.split(".")[0],
            title: data3.name.replace("’","'"), 
            qty: qty, 
            size: variants.length > 1 ? size : "NA", 
            id: urlParams.get("id"), 
            exid: data3.external_id, 
            sid: getVariant(data3.name.split(":")[0]).id,
            product: getVariant(data3.name.split(":")[0]).product,
            variant_id: getVariant(data3.name.split(":")[0]).variant_id}), 1);
          setAddedItem(!addedItem);
          context.update_count(context.cartCount+1);
          }
          }}>Add to Cart</button>
            <button style={{ display: `flex`, justifyContent: `flex-end`,  marginLeft: width > 975 ? `50px` : `0px`, display: `block`, marginBottom: width > 975 ? `0px` : `50px`, color: `white`, cursor: `pointer`, textTransform: `uppercase`}} 
        onClick={() => {
          if (isLoggedIn()){
            setOpenReviewModal(!openReviewModal)
          }
          else{
            window.location.href = "/login"+window.location.search;
          }
          }}>Add a Review</button>
        </div>
        <div style={{display: `block`, width: width > 975 ? `auto` : `85vw`, marginTop: `30px`, maxWidth: width > 600 ? `580px` : `none`}}>
        <div style={{display: `flex`}}><p>Fit: As Expected</p>{data3.name.split(":")[0].includes("Joggers") && <div style={{marginLeft: `50px`, cursor: `pointer`}} onClick={() => {setShowGuideline(!showGuidline)}}>Size Guide</div>}</div>
        {data3.name.split(":")[0].includes("Joggers") && <><p>These joggers are the perfect item for trendy athleisure and streetwear collections (or for simply lounging around the house).</p>
        <ul style={{color: `black`}}>
            <li>70% polyester, 27% cotton, 3% elastane</li>
            <li>Soft cotton-feel fabric face</li>
            <li>brushed fleece fabric underlining (white)</li>
            <li>Slim fit</li>
            <li>Cuffed legs</li>
            <li>Practical pockets</li>
            <li>Elastic waistband with a white drawstring</li>
        </ul></>}
        {data3.name.split(":")[0].includes("Hoodie") && <><p>
        This Hoodie has a relaxed fit and super soft fabric thanks to the unique cotton and polyester blend.
        The brushed fleece inside makes this hoodie a true wardrobe favorite!
      </p>
      <ul style={{color: `black`}}>
          <li> 70% polyester, 27% cotton, 3% elastane </li>
          <li> Fabric weight: 8.85 oz/yd² (300 g/m²), weight may vary by 5% </li>
          <li> Soft cotton-feel fabric face </li>
          <li> Brushed fleece fabric underlining (white) </li>
          <li> Double-lined hood with design on both sides </li>
          <li> White or black drawstrings </li>
          <li> Overlock seams </li>
      </ul>
        </>}
        {data3.name.split(":")[0].includes("Shoes") && <><p>
        More than just a shoe, it’s a statement. These Lace-Up Canvas Shoes are ready to rep your authentic style.
      </p>
      <ul style={{color: `black`}}>
          <li> 100% polyester canvas upper side </li>
          <li> Ethylene-vinyl acetate (EVA) rubber outsole </li>
          <li> Breathable lining </li>
          <li> Padded collar and tongue </li>
          <li> Removable insole </li>
          <li> White laces </li>
          <li> fits true to size</li>
          <li> Blank product sourced from China </li>
      </ul>
        </>}
        {data3.name.split(":")[0].includes("Onesie") && <><p>
        Nothing matters more than a baby's comfort, and this one piece is the right choice for all active babies. 
        The envelope neckline and three snap leg closure ensure comfort throughout the day.
        </p>
        <ul style={{color: `black`}}>
          <li>100% ring-spun combed cotton</li>
          <li>Greys and Blue colors are 52% ring-spun combed cotton and 48% polyester</li>
          <li>Fabric weight: 3.9 oz/y² (132.2 g/m²)</li>
          <li>Side-seamed construction</li>
          <li>Envelope neckline</li>
          <li>Three-snap leg closure</li>    
        </ul>
        </>}
        {data3.name.split(":")[0].includes("Men’s T-shirt") && <><p>
        This premium-quality Crew Neck T-Shirt, is made from a super smooth and comfortable cotton touch polyester jersey that won’t fade after washing.
        </p>
        <ul style={{color: `black`}}>
            <li> 95% polyester, 5% elastane (fabric composition may vary by 1%) </li>
            <li> Fabric weight: 6.19 oz/yd² (210 g/m²), weight may vary by 5% </li>
            <li> Premium knit mid-weight jersey </li>
            <li> Four-way stretch fabric that stretches and recovers on the cross and lengthwise grains </li>
            <li> Regular fit </li>
            <li> white underlining </li>
        </ul>
        </>}
        {data3.name.split(":")[0].includes("Men’s Tank Top") && <><p>
        This premium quality Men's Tank Top with its comfortable, relaxed fit, is perfect for a sunny day out or as an extra layer on those colder days.
        </p>
        <ul style={{color: `black`}}>
          <li>95% polyester, 5% elastane (fabric composition may vary by 1%)</li>
          <li>Fabric weight: 6.19 oz/yd² (210 g/m²), weight may vary by 5%</li>
          <li>Four-way stretch material stretches and recovers on the cross and lengthwise grains</li>
          <li>white underlining</li>
        </ul>
        </>}
        {data3.name.split(":")[0].includes("Men’s Long Sleeve Shirt") && <><p>
        With its classic and regular fit, this Men's Long Sleeve Shirt is a true wardrobe essential. 
        It'll look great on its own or layered under a jacket and will be perfect for a relaxed and casual setting.
        </p>
        <ul style={{color: `black`}}>
          <li>100% cotton</li>
          <li>Sport Grey is 90% cotton, 10% polyester</li>
          <li>Fabric weight: 6.0 oz/yd² (203.43 g/m²)</li>
          <li>Classic fit with long sleeves and rib cuffs</li>
          <li>Pre-shrunk jersey knit</li>
          <li>Seamless double-needle ⅞ ″ (2.2 cm) collar</li>
          <li>Double-needle bottom hem</li>
          <li>Taped neck and shoulders</li>
          <li>Quarter-turned to avoid crease down the middle</li>
          <li>Runs a little Tight</li> 
        </ul>
        </>}
        {data3.name.split(":")[0].includes("Women's T-shirt") && <><p>
        This premium-quality Women's Crew Neck T-Shirt, is made from super smooth polyester. The tee is stretchy, comfortable, and perfect for everyday wear. 
        </p>
        <ul style={{color: `black`}}>
            <li> 95% polyester, 5% elastane (fabric composition may vary by 1%) </li>
            <li> Fabric weight: 6.19 oz/yd² (210 g/m²) </li>
            <li> Premium knit mid-weight jersey </li>
            <li> Four-way stretch fabric that stretches and recovers on the cross and lengthwise grains </li>
            <li> Regular fit </li>
            <li> white underlining</li>
        </ul>
        </>}
        {data3.name.split(":")[0].includes("Women's Tank Top") && <><p>
        This form-fitting Women's Tank Top is a must-have for every activewear collection!
        Thanks to its smooth microfiber yarn and stretchy material, the top is perfect for both active training or a casual day out.
        </p>
        <ul style={{color: `black`}}>
          <li>82% polyester, 18% spandex</li>
          <li>Fabric weight: 6.78 oz/yd² (230 g/m²) (weight may vary by 5%)</li>
          <li>Four-way stretch fabric that stretches and recovers on the cross and lengthwise grains</li>
          <li>Made with a smooth and comfortable microfiber yarn</li>
          <li>Top sits just below the hips</li>
          <li>Dipped hem</li>
          <li>white underlining</li>
        </ul>
        </>}
        {data3.name.split(":")[0].includes("Women's Crop Top") && <><p>
        Let this body-hugging  Crop Top become the centerpiece of any summer outfit!
        </p>
        <ul style={{color: `black`}}>
          <li>82% polyester, 18% spandex</li>
          <li>Fabric weight (may vary by 5%): 6.78 oz/yd² (230 g/m²)</li>
          <li>Made with a smooth and comfortable microfiber yarn</li>
          <li>Material has a four-way stretch, so fabric stretches and recovers on the cross and lengthwise grains</li>
          <li>Body-hugging fit</li>
        </ul>
        </>}
        {data3.name.split(":")[0].includes("Women's legging") && <><p>
        You’ve found them—the most practical pair of leggings yet. 
        Combining a classic, high-waisted cut and two side pockets, these leggings with pockets are assembled for efficiency.
        </p>
        <ul style={{color: `black`}}>
          <li>82% polyester, 18% spandex</li>
          <li>Fabric weight (may vary by 5%): 6.78 oz/yd² (230 g/m²)</li>
          <li>UPF 50+</li>
          <li>Four-way stretch fabric with a soft and luxurious feel</li>
          <li>Comfortable and secure fit</li>
          <li>Flattering cut</li>
          <li>High-waisted</li>
          <li>Double-layered, comfortable waistband</li>
          <li>1 pocket on each side</li>
          <li>Triangle gusset</li>
          <li> white underlining</li>
        </ul>
        <p>Please note that contact with rough surfaces should be avoided since they can pull out the white fibers in the fabric, damaging the leggings.</p>
        </>}
        {data3.name.split(":")[0].includes("Women's Swimsuit") && <><p>
        Let this swimsuit with it cheeky fit, low-scoop back, and beautiful designs become your instant favorite!
        </p>
        <ul style={{color: `black`}}>
          <li>82% polyester, 18% spandex</li>
          <li>Fabric weight: 6.78 oz/yd² (230 g/m²), weight may vary by 5%</li>
          <li>Cheeky fit</li>
          <li>Scoop neckline and low-scoop back</li>
          <li>Zig-zag stitching</li>
          <li>Double-layer front </li>
          <li>Four-way stretch material stretches and recovers on the cross and lengthwise grains</li>
          <li> white underlining</li>
        </ul>
        <p>Disclaimer: To make your One-Piece Swimsuit last longer, thoroughly rinse it off after each use and get rid of any chlorine/salt residue.
          Please note that contact with rough surfaces and velcro fasteners should be avoided since they can pull out the white fibers in the fabric, damaging the swimsuit’s appearance.
      </p>
        </>}
        {data3.name.split(":")[0].includes("Duffle Bag") && <><p>
        This Duffle Bag is the perfect spacious bag no matter the occasion. It’s great for packing exercise gear when heading to the gym, or throwing in necessities and going on an adventure.
        Your stuff will stay organized and safe with the bag’s multiple inside pockets. The bag’s shoulder strap is adjustable or removable, while the dual padded handles with velcro fastening ensure easy carrying.
        </p>
        <ul style={{color: `black`}}>
        <li> 100% polyester with black interlining </li>
        <li> Fabric weight: 9.56 oz/yd² (325 g/m²), weight may vary by 5% </li>
        <li> One size: 22″ × 11.5″ × 11.5″ (55.9 × 29.2 × 29.2 cm) </li>
        <li> Capacity: 13.2 gallons (50 l) </li>
        <li> Max weight: 66 lbs (30 kg) </li>
        <li> T-piping for stability </li>
        <li> Adjustable and removable padded shoulder strap </li>
        <li> Dual padded handles with hoop & loop fasteners for easy carrying </li>
        <li> Mesh side pocket </li>
        <li> Multiple inside pockets </li>
        <li> Polyethylene bottom support for stability </li> 
        </ul>
        </>}
        {data3.name.split(":")[0].includes("Tote") && <><p>
        Meet your companion for any occasion!! This tote is spacious, comfy, and a great choice no matter where you are going. 
        Take it with you on your next beach trip or on a stroll around town. 
        </p>
        <ul style={{color: `black`}}>
          <li>100% polyester</li>
          <li>Fabric weight in the US (may vary by 5%): 7.22 oz/yd² (245 g/m²)</li>
          <li>Fabric weight in the EU (may vary by 5%): 6.64 oz/yd² (225 g/m²)</li>
          <li>Maximum weight limit: 44lbs (20kg)</li>
          <li>Capacity: 4.4 US gal (17.8l)</li>
          <li>Large inside pocket</li>
          <li>Comfortable cotton webbing handles</li>
          <li>Vibrant colors that won't fade</li>
        </ul>
        </>}
        <p>Shipping: Items are made to order, so the average fulfillment & delivery time is 8-13 days.</p>
        </div>
        <div>
          {(data3 && data3.name) && <GetReviews cat={data3.name.split(":")[0]} />}
        </div>
        </div></div>
        </div>}     <Modal
        className="termsModal"
        size={"md"}
        style={{ border: `none` }}
        show={openReviewModal}
        onHide={() => {setOpenReviewModal(!openReviewModal)}}
        centered
        scrollable={true}
      >
        <Modal.Header
          style={{
            backgroundImage: `linear-gradient(red, rgb(252,195,10))`,
            height: `40px`,
            fontWeight: `bold`,
            color: `white`,
            border: `none`,
            
          }}
          closeButton={false}
        >
          <Modal.Title className="modalHeader">
            Submit a Review
          </Modal.Title>
          <img
            style={{
              marginTop: `-6px`,
              marginRight: `-10px`,
              cursor: "pointer",
              height: `20px`,
            }}
            onClick={() => {setOpenReviewModal(!openReviewModal)}}
            src={window.location.origin + "/Images/closeTerms.png"}
            alt="close button"
          />
        </Modal.Header>
        <Modal.Body
          style={{
            background: `linear-gradient(rgba(255, 100, 0, 0.2), rgba(252, 195, 10, 0.2))`,
            border: `none`,
            maxHeight: `80vh`,
            overflowY: `auto`,
          }}
        >
          <Review id={urlParams.get("id")} exid={urlParams.get("exid")} cat={(data3 && data3.name) ? data3.name.split(":")[0] : ''} close={() => {setOpenReviewModal(!openReviewModal)}} />
        </Modal.Body>
      </Modal>
      </>
  )
}
