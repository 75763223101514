import * as React from "react"
import Layout from "../Components/layout";
import ItemDetails from "../Components/itemDetails";
import { Seo } from "../Components/seo";


export default function ItemDetail() {
  return (
    <Layout>
      <ItemDetails />
    </Layout>
  )
}

export const Head = () => (
  <Seo />
)